<template>
  <div style="height: 100%">
    <div style="height: 40px"></div>
    <div class="opeDashboard">
      <el-row :gutter="14">
        <!-- 基本信息 -->
        <el-col :span="19">
          <!-- 基本信息 上 -->
          <el-row>
            <div class="opeDashboard-msg bg">
              <div class="title">{{ baseData.tenantName || "基本信息" }}</div>
              <div class="opeDashboard-msg-top">
                <div v-for="(item, index) in baseMsgList" :key="index">
                  <img :src="item.url" alt="" />
                  <p>
                    <el-tooltip effect="dark" :content="item.name">
                      <span
                        :style="
                          item.fontSize ? { fontSize: item.fontSize } : {}
                        "
                        >{{ item.name || "无" }}</span
                      >
                    </el-tooltip>
                    <span>{{ item.address }}</span>
                  </p>
                </div>
              </div>
            </div>
          </el-row>
          <!-- 基本信息 下 -->
          <el-row :gutter="20" class="opeDashboard-msg-bottom">
            <el-col :span="6">
              <div class="opeDashboard-msg-bottom-people bg">
                <div>
                  <p>住宅总人数（人）</p>
                  <p><i class="el-icon-caret-bottom"></i></p>
                  <p>{{ baseData.tenantAllPersonCount || 0 }}</p>
                </div>
                <div>
                  <p>住宅总房屋数（套）</p>
                  <p><i class="el-icon-caret-bottom"></i></p>
                  <p>{{ baseData.allRoomCount || 0 }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <!-- 社区画像 -->
              <div
                class="opeDashboard-msg-bottom-community opeDashboard-common bg"
              >
                <div>
                  <div>
                    <div class="title">社区画像</div>
                  </div>

                  <div id="communityPm"></div>
                </div>

                <!-- 性别图 -->

                <div id="communityGender"></div>
              </div>
            </el-col>
            <el-col :span="8">
              <!-- 人房数据 -->
              <div class="opeDashboard-msg-bottom-pAndH opeDashboard-common bg">
                <!-- 左边 -->
                <div class="opeDashboard-msg-bottom-pAndH-text">
                  <div class="title">人房数据</div>
                  <div class="opeDashboard-msg-bottom-pAndH-text-content">
                    <div>
                      <img src="./img/people.jpg" alt="" />
                      <div>
                        <p>户均人口数（人）</p>
                        <p><i class="el-icon-caret-bottom"></i></p>
                        <p>{{ hjrks || 0 }}</p>
                      </div>
                    </div>
                    <div>
                      <img src="./img/house.jpg" alt="" />
                      <div>
                        <p>注册房屋数（套）</p>
                        <p><i class="el-icon-caret-bottom"></i></p>
                        <p>{{ this.baseData.registerRoomCount }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 右边 -->
                <div class="opeDashboard-msg-bottom-pAndH-echarts">
                  <div id="rfPie"></div>
                  <div id="zcPie"></div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <!-- 专项运营信息 -->
        <el-col :span="5">
          <div class="opeDashboard-zxyyMsg bg">
            <div class="title">专项运营信息</div>
            <div class="opeDashboard-zxyyMsg-content">
              <div v-for="(item, index) in operationMsgList" :key="index">
                <img :src="item.url" alt="" />
                <div>
                  <el-tooltip effect="dark" :content="item.name">
                    <p>{{ item.name }}</p>
                  </el-tooltip>
                  <p>{{ item.type }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 使用情况 -->
      <el-row :gutter="20" class="mt20">
        <!-- 居民端使用人数 -->
        <el-col :span="6">
          <div class="opeDashboard-useNumber opeDashboard-common bg">
            <div class="title">居民端使用人数</div>

            <div id="useNumberEchart"></div>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="opeDashboard-addPeople opeDashboard-common bg">
            <div class="title">
              <span>新增人数</span>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.addPeopleSelect"
                @change="addPeopleSelOnChange"
              />
            </div>
            <div id="addPeopleEchart"></div>
          </div>
        </el-col>
      </el-row>

      <!-- 活跃沉默统计 -->
      <el-row :gutter="20" class="mt20">
        <!-- 活跃人数 -->
        <el-col :span="18">
          <div class="opeDashboard-hyrs opeDashboard-common bg">
            <div class="title">
              <span>活跃人数</span>
              <!-- activePeopleSelOnChange -->
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.activePeopleSelect"
                @change="activePeopleSelOnChange"
              />
            </div>
            <div class="opeDashboard-hyrs-echarts">
              <div id="hyrsPie"></div>
              <div id="hyrsLine"></div>
            </div>
          </div>
        </el-col>
        <!-- 沉默人数 -->
        <el-col :span="6">
          <div class="opeDashboard-cmrs opeDashboard-common bg">
            <div class="title">超过90天沉默人数</div>

            <div id="silentPie"></div>
          </div>
        </el-col>
      </el-row>

      <!-- 日活统计 点击排行 -->
      <el-row :gutter="20" class="mt20">
        <!-- 日活统计 -->
        <el-col :span="16">
          <div class="opeDashboard-rhtj opeDashboard-common bg">
            <div class="title">近30日日活统计</div>
            <div id="rhtjLine"></div>
          </div>
        </el-col>
        <!-- 点击排行 -->
        <el-col :span="8">
          <div class="opeDashboard-djph opeDashboard-common bg">
            <div class="title">近30日应用点击排行（前三）</div>
            <div id="djph"></div>
          </div>
        </el-col>
      </el-row>

      <!-- 商品 积分信息 -->
      <el-row :gutter="20" class="mt20">
        <el-col :span="6">
          <div class="opeDashboard-spdh opeDashboard-common bg">
            <div class="title">商品兑换分析</div>

            <div class="opeDashboard-spdh-content">
              <p>
                <span>商品名称</span>
                <span>兑换月变化幅度</span>
                <span>月兑换量</span>
              </p>
              <p class="pbody" v-for="(item, index) in goodsList" :key="index">
                <el-tooltip effect="dark" :content="item.goodsName">
                  <span>
                    <img src="./img/one.png" alt="" v-if="index === 0" />
                    <img src="./img/two.png" alt="" v-if="index === 1" />
                    <img src="./img/three.png" alt="" v-if="index === 2" />
                    <span v-if="index > 2">{{ index + 1 }}</span>
                    {{ item.goodsName }}
                  </span>
                </el-tooltip>
                <span>
                  <img src="./img/up.png" alt="" v-if="item.changeRange > 0" />
                  <img
                    src="./img/down.png"
                    alt=""
                    v-if="item.changeRange < 0"
                  />
                  <i>{{ item.changeRange }} %</i>
                </span>
                <span>{{ item.count }}</span>
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="opeDashboard-hxl opeDashboard-common bg">
            <div class="title">
              <span>积分订单核销率</span>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.hxlValue"
                @change="handleHxlChange"
                :width="100"
              />
            </div>

            <div id="hxlPie"></div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="opeDashboard-xfl opeDashboard-common bg">
            <div class="title">
              <span>积分消费率</span>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.xflValue"
                :width="100"
                @change="handleXflChange"
              />
            </div>

            <div id="xflPie"></div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="opeDashboard-hdtj opeDashboard-common bg">
            <div class="title">
              <span>活动统计</span>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.hdtjSelectValue"
                @change="handleHdtjChange"
              />
            </div>

            <div class="opeDashboard-hdtj-content">
              <div class="opeDashboard-hdtj-content-top">
                <div>
                  <p>活动场</p>
                  <p><i class="el-icon-caret-bottom"></i></p>
                  <p>{{ activityObj.activityCount }}</p>
                </div>
                <div>
                  <p>活动人数</p>
                  <p><i class="el-icon-caret-bottom"></i></p>
                  <p>{{ activityObj.activityPersonCount }}</p>
                </div>
              </div>
              <div class="opeDashboard-hdtj-content-bottom">
                <div>
                  <p>活动参与人数</p>
                  <p><i class="el-icon-caret-bottom"></i></p>
                  <p>{{ activityObj.joinCount }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 空间预约 -->
      <el-row :gutter="20" class="mt20">
        <el-col :span="9">
          <div class="opeDashboard-yycs opeDashboard-common bg">
            <div class="title">
              <span>空间预约次数</span>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.kjyycs"
                @change="kjyycsSelOnChange"
              />
            </div>

            <div id="yycsLine"></div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="opeDashboard-pmqs opeDashboard-common bg">
            <el-tooltip effect="dark" content="近30日空间预约次数排名前三">
              <div class="title">近30日空间预约次数排名前三</div>
            </el-tooltip>

            <div id="pmqs"></div>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="opeDashboard-pmms opeDashboard-common bg">
            <div class="title">近30日空间预约次数排名末三</div>

            <div class="opeDashboard-pmms-content">
              <div v-for="(item, index) in lastYyphList" :key="index">
                <p>{{ item.data }}</p>
                <p>{{ item.count }}</p>
              </div>
              <!-- <div>
                <p>舞蹈室</p>
                <p>75</p>
              </div>
              <div>
                <p>金钟文化室</p>
                <p>65</p>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="opeDashboard-yykjs">
            <p>可预约空间数</p>
            <p>{{ canPlaceOrderObj.all }}个</p>
            <!-- <p>较去年变化量：<span>{{ canPlaceOrderObj.forLastYear }}</span>个</p> -->
          </div>
        </el-col>
      </el-row>
      <!-- 最后一层 -->
      <el-row :gutter="20" class="mt20">
        <el-col :span="9">
          <div class="opeDashboard-sjsb opeDashboard-common bg">
            <div class="title">事件上报</div>

            <div id="sjsbPie"></div>
          </div>
        </el-col>

        <el-col :span="5">
          <div class="opeDashboard-wcl opeDashboard-common bg">
            <div class="title">近30日事件完成率</div>

            <div id="wclPie"></div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="opeDashboard-sbqk opeDashboard-common bg">
            <div class="title">今日设备情况</div>
            <div class="opeDashboard-sbqk-content">
              <div id="sbqkPie"></div>
              <div>
                <img src="./img/shebei2.png" alt="" />
                <div>
                  <p>{{ baseData.deviceOfflineCount }}</p>
                  <p>离线设备数量</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  drawRfPie,
  drawCommunityPm,
  drawCommunityGender,
  drawUseNumberChart,
  drawAddPeopleChart,
  drawHyrsLine,
  drawCommonPie,
  drawSilentPie,
  drawRhtjLine,
  drawDjph,
  drawSjsbPie,
  drawYycsLine,
  drawPmqs,
  drawSdtPie,
} from "./echart";
import { debounce } from "@/utils/utils";
import {
  getSinglePointStatistics,
  getGoodsExchangeDetails,
  getDjphList,
  getActivityDetails,
  getAddPeopleNumberUrl,
  getCanPlaceOrder,
  getPlaceOrderPlThree,
  getJfddhxl,
  getPointXfl,
  getActivePeopleNumberUrl,
  getJingThirtyDayActiveUrl,
  getKjyycsUrl,
  getSqhxDetails,
  getHjrks,
} from "./api.js";
// import countTo from "vue-count-to";
// import moment from "moment";
export default {
  name: "runCondition",
  data() {
    return {
      hjrks: "", //户均人口数
      baseData: {},
      searchParam: {
        addPeopleSelect: "month",
        activePeopleSelect: "month",
        kjyycs: "month",
        addPeopleTime: 1,
        hdtjSelectValue: "month",
        hxlValue: "month",
        xflValue: "month",
      },
      timeSelectOptions: [
        { label: "近30日", value: "month" },
        { label: "本年度", value: "year" },
      ],
      // 基本信息 - 上
      baseMsgList: [],
      // 专项运营信息
      operationMsgList: [
        {
          url: "",
          name: "",
          type: "专项运营单位(一老)",
        },
        {
          url: "",
          name: "",
          type: "专项运营单位(一小)",
        },
        {
          url: "",
          name: "",
          type: "完成养老机构数字化对接",
        },
        {
          url: "",
          name: "",
          type: "完成托育机构数字化对接",
        },
        {
          url: "",
          name: "",
          type: "完成社区食堂数字化对接",
        },
      ],
      goodsList: [], // 商品列表
      activityObj: {}, //活动统计
      canPlaceOrderObj: {}, // 可预约空间数量
      lastYyphList: [], //近30日空间预约次数排名末三

      useNumberChart: "", //居民端使用人数饼图
      rfPieChart: "", //人房匹配率
      zcPieChart: "", // 注册覆盖率
      hylPieChart: "", // 近30日活跃率
      sjsbPieChart: "", //事件上报
      wclPieChart: "", //近30日完成率
      cmlPieChart: "", //沉默率
      sbqkChart: "", //设备情况
      djphChart: "", // 点击排行
      pmqsChart: "", //排名前三
      hxlChart: "", //核销率
      xflChart: "", //消费率
      communityPmChart: "", //社区排名图
      communityGenderChart: "", //性别图
      addPeopleChart: "", //新增人数
      hyrsChart: "", //活跃人数
      rhtjChart: "", //日活点击
      yycsChart: "", // 预约次数
    };
  },
  components: {
    // countTo,
  },
  created() {
    this.initData();

    // 获取商品兑换分析列表
    this.getGoodExchangeDetails();
    // 获取近30日点击排行列表
    this.getDjphList();
    // 获取活动统计数据
    this.getActivityData();
    // 获取空间预约数
    this.getCanPlaceOrder();
    // 获取空间预约次数排行
    this.getPlaceOrderPlThree();
    // 获取核销率
    this.getJfddhxl();
    // 获取消费率
    this.getPointXfl();
    // 获取社区画像数据
    this.getSqhxDetails();
    // 获取人房户均人口数据
    this.getHjrks();

    this.getAddPeopleNumberData();
    this.getActivePeopleNumberData();
    this.getJingThirtyDayActiveData();
    this.getkjyycsData();
  },
  methods: {
    async initData() {
      let _this = this;
      const res = await this.$axios.get(getSinglePointStatistics, {
        params: {
          tenantId: "",
          curPage: 1,
          pageSize: 99999,
        },
      });
      if (res.success && res.code === 200) {
        const { data } = res;

        // 基础信息
        this.baseData = JSON.parse(JSON.stringify(data));

        // 基础信息数据
        this.baseMsgList = [
          {
            url: require("@/views/analysisData/opeDashboard/img/house.png"),
            name: this.baseData.tenantQx,
            address: "所在区县",
          },
          {
            url: require("@/views/analysisData/opeDashboard/img/house.png"),
            name: this.baseData.tenantJd,
            address: "所在街道",
          },
          {
            url: require("@/views/analysisData/opeDashboard/img/video.png"),
            name: this.baseData.operatingUnit,
            address: "运营主体单位",
            fontSize: "17px",
          },
          {
            url: require("@/views/analysisData/opeDashboard/img/house.png"),
            name: this.baseData.operatingUnitType,
            address: "运营单位性质",
          },
        ];

        //专项运营信息

        this.operationMsgList = [
          {
            url: require("@/views/analysisData/opeDashboard/img/operationMsg.png"),
            name: this.baseData.specialOperatingUnitOld || "无",
            type: "专项运营单位(一老)",
          },
          {
            url: require("@/views/analysisData/opeDashboard/img/yixiao.png"),
            name: this.baseData.specialOperatingUnitYoung || "无",
            type: "专项运营单位(一小)",
          },
          {
            url: require("@/views/analysisData/opeDashboard/img/yljg.png"),
            name: this.baseData.isFinshYldj || "否",
            type: "完成养老机构数字化对接",
          },
          {
            url: require("@/views/analysisData/opeDashboard/img/tyjg.png"),
            name: this.baseData.isFinshTydj || "否",
            type: "完成托育机构数字化对接",
          },
          {
            url: require("@/views/analysisData/opeDashboard/img/sqst.png"),
            name: this.baseData.isFinshStdj || "否",
            type: "完成社区食堂数字化对接",
          },
        ];

        // 居民端使用人数
        let useNumSeriesData = [
          {
            value: this.baseData.registerPersonCount || 0,
            name: "注册人数",
            sum: Number(this.baseData.registerPersonCount) + Number(this.baseData.yks),
          },
          {
            value: this.baseData.yks || 0,
            name: "游客人数",
            sum: Number(this.baseData.registerPersonCount) + Number(this.baseData.yks),
          },
        ];
        drawUseNumberChart("useNumberEchart", useNumSeriesData);
        this.useNumberChart = debounce(() => {
          drawUseNumberChart("interfaceLine", useNumSeriesData).resize();
        }, 100);
        window.addEventListener("resize", _this.useNumberChart, true);

        // 人房数据 - 人房匹配率
        let rfgtppl = this.filterUnit(this.baseData.personRoomPenetrationMatchingRate);
        let rfTitle = [
          {
            text: rfgtppl + "%",
            x: "center",
            top: "50%",
            textStyle: {
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.85)",
              foontWeight: "600",
            },
          },
          {
            text: "人房匹配率",
            x: "center",
            top: "32%",
            textStyle: {
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: 14,
              fontWeight: "400",
            },
          },
        ];

        drawRfPie("rfPie", rfgtppl, rfTitle, "#53DAAB");
        this.rfPieChart = debounce(() => {
          drawRfPie("rfPie", rfgtppl, rfTitle, "#53DAAB").resize();
        }, 100);
        window.addEventListener("resize", _this.rfPieChart, true);

        // 去除%
        let zcfwfgl = this.filterUnit(this.baseData.registerRoomMatchingRate);
        // 注册房屋数
        let zcTitle = [
          {
            text: zcfwfgl + "%",
            x: "center",
            top: "50%",
            textStyle: {
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.85)",
              foontWeight: "600",
            },
          },
          {
            text: "注册房屋覆盖率",
            x: "center",
            top: "32%",
            textStyle: {
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: 14,
              fontWeight: "400",
            },
          },
        ];
        drawRfPie("zcPie", zcfwfgl, zcTitle, "#FFCE6F");
        this.zcPieChart = debounce(() => {
          drawRfPie("zcPie", zcfwfgl, zcTitle, "#FFCE6F").resize();
        }, 100);
        window.addEventListener("resize", _this.zcPieChart, true);

        // 活跃人数
        // 活跃人数-饼图
        let thirtyHyl = this.filterUnit(this.baseData.activeRate);
        let hyrsTitle = [
          {
            text: thirtyHyl + "%",
            x: "center",
            top: "50%",
            textStyle: {
              fontSize: 16,
              color: "#2CA9F5",
              foontWeight: "600",
            },
          },
          {
            text: "近30日活跃率",
            x: "center",
            top: "40%",
            textStyle: {
              color: "#2CA9F5",
              fontSize: 14,
              fontWeight: "600",
            },
          },
        ];
        drawCommonPie("hyrsPie", thirtyHyl, hyrsTitle);
        this.hylPieChart = debounce(() => {
          drawCommonPie("hyrsPie", thirtyHyl, hyrsTitle).resize();
        }, 100);
        window.addEventListener("resize", _this.hylPieChart, true);

        // 事件上报
        let sjsbSeriesData = [
          {
            value: Number(this.baseData.finshWorkInfoCount),
            name: "事件完成总数",
          },
          {
            value:
              Number(this.baseData.workInfoCount) -
              Number(this.baseData.finshWorkInfoCount),
            name: "事件未完成总数",
          },
        ];

        let sjsbTitle = [
          {
            text: "上报事件总数",
            x: "center",
            top: "40%",
            textStyle: {
              fontSize: 14,
              color: "rgba(134,134,134,0.8)",
              foontWeight: "100",
            },
          },
          {
            text: this.baseData.workInfoCount + "件",
            x: "center",
            top: "50%",
            textStyle: {
              color: "rgba(0, 0, 0, 0.85)",
              fontSize: 20,
              fontWeight: "600",
            },
          },
        ];
        drawSjsbPie("sjsbPie", sjsbSeriesData, sjsbTitle);
        this.sjsbPieChart = debounce(() => {
          drawSjsbPie("sjsbPie", sjsbSeriesData, sjsbTitle).resize();
        }, 100);
        window.addEventListener("resize", _this.sjsbPieChart, true);
        // 近30日事件完成率
        let thirtyWorkFinshl = this.filterUnit(this.baseData.thirtyWorkFinshl);
        let wclTitle = [
          {
            text: thirtyWorkFinshl + "%",
            x: "center",
            y: "center",
            textStyle: {
              fontSize: 16,
              color: "#2CA9F5",
              foontWeight: "600",
            },
          },
        ];
        drawCommonPie("wclPie", thirtyWorkFinshl, wclTitle);
        this.wclPieChart = debounce(() => {
          drawCommonPie("wclPie", thirtyWorkFinshl, wclTitle).resize();
        }, 100);
        window.addEventListener("resize", _this.wclPieChart, true);

        // 沉默率
        let ninetyCml = this.filterUnit(this.baseData.silentRate);

        let cmlTitle = [
          {
            text: "沉默人数",
            x: "center",
            top: "35%",
            textStyle: {
              fontSize: 14,
              color: "rgba(134,134,134,0.8)",
              foontWeight: "100",
            },
          },
          {
            text: this.baseData.silentNumber,
            x: "center",
            top: "45%",
            textStyle: {
              color: "rgba(0, 0, 0, 0.85)",
              fontSize: 20,
              fontWeight: "600",
            },
          },
        ];
        drawSilentPie(
          "silentPie",
          Number(ninetyCml),
          Number(this.baseData.silentNumber),
          cmlTitle
        );
        this.cmlPieChart = debounce(() => {
          drawSilentPie(
            "silentPie",
            Number(ninetyCml),
            Number(this.baseData.silentNumber),
            cmlTitle
          ).resize();
        }, 100);
        window.addEventListener("resize", _this.cmlPieChart, true);

        // 设备情况
        let deviceOnLinel = this.filterUnit(this.baseData.deviceOnlineRate);
        drawSdtPie("sbqkPie", Number(deviceOnLinel), "设备在线率");
        this.sbqkChart = debounce(() => {
          drawSdtPie("sbqkPie", Number(deviceOnLinel), "设备在线率").resize();
        }, 100);
        window.addEventListener("resize", _this.sbqkChart, true);
      }
    },
    // 获取人房户均人口数据
    async getHjrks() {
      const res = await this.$axios.get(getHjrks);
      if (res.code === 200 && res.success) {
        this.hjrks = res.data;
      }
    },
    // 获取商品兑换分析列表
    async getGoodExchangeDetails() {
      const res = await this.$axios.get(getGoodsExchangeDetails);

      if (res.code === 200 && res.success) {
        this.goodsList = res.data;
        // this.goodsList = [res.data[0], res.data[0], res.data[0],res.data[0],res.data[0]]
      }
    },
    // 近30日应用点击排名
    async getDjphList() {
      let _this = this;
      const res = await this.$axios.get(getDjphList);

      if (res.code === 200 && res.success) {
        let yData = res.data.map((item) => {
          return item.data;
        });
        let seriesData = res.data.map((item) => {
          return item.count;
        });
        // 获取总值
        let sumList = [];
        for (let i in res.data) {
          sumList.push(res.data[0].count);
        }
        // 点击排行
        drawDjph("djph", yData, seriesData, sumList);
        this.djphChart = debounce(() => {
          drawDjph("djph", yData, seriesData, sumList).resize();
        }, 100);
        window.addEventListener("resize", _this.djphChart, true);
      }
    },

    async getActivityData() {
      const res = await this.$axios.get(getActivityDetails, {
        params: {
          selectLatitude: this.searchParam.hdtjSelectValue,
        },
      });
      if (res.code === 200 && res.success) {
        this.activityObj = JSON.parse(JSON.stringify(res.data[0]));
      }
    },

    handleHdtjChange() {
      this.getActivityData();
    },
    // 可预约空间数
    async getCanPlaceOrder() {
      const res = await this.$axios.get(getCanPlaceOrder);
      if (res.code === 200 && res.success) {
        this.canPlaceOrderObj = JSON.parse(JSON.stringify(res.data));
      }
    },
    // 预约次数排行
    async getPlaceOrderPlThree() {
      let _this = this;
      const res = await this.$axios.get(getPlaceOrderPlThree);
      if (res.code === 200 && res.success) {
        // 末三
        this.lastYyphList = res.data.lastThree ? res.data.lastThree : [];

        // 前三
        let firstThree = res.data.firstThree.reverse();

        let xData = firstThree.map((item) => {
          return item.data;
        });
        let seriesData = firstThree.map((item) => {
          return item.count;
        });

        let maxList = [];
        for (let key in firstThree) {
          maxList.push(firstThree[firstThree.length - 1].count);
        }

        drawPmqs("pmqs", xData, seriesData, maxList);
        this.pmqsChart = debounce(() => {
          drawPmqs("pmqs", xData, seriesData, maxList).resize();
        }, 100);
        window.addEventListener("resize", _this.pmqsChart, true);
      }
    },

    // 核销率
    async getJfddhxl() {
      let _this = this;
      const res = await this.$axios.get(getJfddhxl, {
        params: {
          selectLatitude: this.searchParam.hxlValue,
        },
      });

      if (res.code === 200 && res.success) {
        let hxlTitle = [
          {
            text: res.data + "%",
            x: "center",
            top: "50%",
            textStyle: {
              fontSize: 16,
              color: "#2CA9F5",
              foontWeight: "600",
            },
          },
          {
            text: "核销率",
            x: "center",
            top: "40%",
            textStyle: {
              color: "#2CA9F5",
              fontSize: 14,
              fontWeight: "600",
            },
          },
        ];
        // 核销率
        drawCommonPie("hxlPie", res.data, hxlTitle);
        if (!this.hxlChart) {
          this.hxlChart = debounce(() => {
            drawCommonPie("hxlPie", res.data, hxlTitle).resize();
          }, 100);
          window.addEventListener("resize", _this.hxlChart, true);
        }
      }
    },
    // 核销率切换事件
    handleHxlChange() {
      this.getJfddhxl();
    },
    // 消费率
    async getPointXfl() {
      let _this = this;
      const res = await this.$axios.get(getPointXfl, {
        params: {
          selectLatitude: this.searchParam.hxlValue,
        },
      });

      if (res.code === 200 && res.success) {
        // 消费率
        drawSdtPie("xflPie", res.data);
        if (!this.hxlChart) {
          this.xflChart = debounce(() => {
            drawSdtPie("xflPie", res.data).resize();
          }, 100);
          window.addEventListener("resize", _this.xflChart, true);
        }
      }
    },
    // 消费率切换事件
    handleXflChange() {
      this.getPointXfl();
    },

    async getSqhxDetails() {
      let _this = this;
      const res = await this.$axios.get(getSqhxDetails);

      if (res.code === 200 && res.success) {
        let labelList = [
          {
            name: "3岁及以下",
            value: "1",
            count: 0,
          },
          {
            name: "4-17岁",
            value: "2",
            count: 0,
          },
          {
            name: "18-59岁",
            value: "3",
            count: 0,
          },
          {
            name: "60-69岁",
            value: "4",
            count: 0,
          },
          {
            name: "70-79岁",
            value: "5",
            count: 0,
          },
          {
            name: "80岁以上",
            value: "6",
            count: 0,
          },
        ];
        // 赋值数据
        labelList.forEach((item) => {
          res.data.forEach((dataItem) => {
            if (item.value === dataItem.data) {
              item.count = dataItem.count;
            }
          });
        });
        // 最大值
        let max = Math.max.apply(
          Math,
          res.data.map(function (o) {
            return o.count; // 需要比较的值
          })
        );

        // 社区画像 - 排名图
        drawCommunityPm("communityPm", labelList, max);
        this.communityPmChart = debounce(() => {
          drawCommunityPm("communityPm", labelList, max).resize();
        }, 100);
        window.addEventListener("resize", _this.communityPmChart, true);

        let genderList = res.data.filter((item) => {
          return item.data === "男" || item.data === "女";
        });

        let seriesData = genderList.map((item) => {
          return {
            value: item.count,
            name: item.data,
          };
        });
        // 社区画像 - 性别图
        drawCommunityGender("communityGender", seriesData);
        this.communityGenderChart = debounce(() => {
          drawCommunityGender("communityGender", seriesData).resize();
        }, 100);
        window.addEventListener("resize", _this.communityGenderChart, true);
      }
    },
    getAddPeopleNumberData() {
      let _this = this;
      this.$axios
        .get(getAddPeopleNumberUrl, {
          params: { selectLatitude: this.searchParam.addPeopleSelect },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.yks = Array.isArray(res.data.yks) ? res.data.yks : [];
            res.data.zcs = Array.isArray(res.data.zcs) ? res.data.zcs : [];
            const seriesData = res.data.yks.map((item, index) => ({
              name: item.date,
              zcrs: res.data.zcs[index].count,
              ykrs: item.count,
            }));
            this.$nextTick(() => {
              drawAddPeopleChart("addPeopleEchart", seriesData);

              if (!this.addPeopleChart) {
                this.addPeopleChart = debounce(() => {
                  drawAddPeopleChart("addPeopleEchart", seriesData).resize();
                }, 100);
                window.addEventListener("resize", _this.addPeopleChart, true);
              }
            });
          }
        })
        .catch(() => {});
    },
    // 新增人数选中变化
    addPeopleSelOnChange(value) {
      this.getAddPeopleNumberData();
    },
    // 活跃人数选中变化
    activePeopleSelOnChange(value) {
      this.getActivePeopleNumberData();
    },
    getActivePeopleNumberData() {
      let _this = this;
      this.$axios
        .get(getActivePeopleNumberUrl, {
          params: { selectLatitude: this.searchParam.activePeopleSelect },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            const seriesData = res.data.map((item, index) => ({
              name: item.date,
              count: item.count,
            }));
            this.$nextTick(() => {
              drawHyrsLine("hyrsLine", seriesData);

              if (!this.hyrsChart) {
                this.hyrsChart = debounce(() => {
                  drawHyrsLine("hyrsLine", seriesData).resize();
                }, 100);
                window.addEventListener("resize", _this.hyrsChart, true);
              }
            });
          }
        })
        .catch(() => {});
    },
    getJingThirtyDayActiveData() {
      let _this = this;
      this.$axios
        .get(getJingThirtyDayActiveUrl, {})
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.dailyActivityResult = Array.isArray(
              res.data.dailyActivityResult
            )
              ? res.data.dailyActivityResult
              : [];
            res.data.dailyDjlresult = Array.isArray(res.data.dailyDjlresult)
              ? res.data.dailyDjlresult
              : [];
            const seriesData = res.data.dailyActivityResult.map(
              (item, index) => ({
                name: item.date,
                djl: res.data.dailyDjlresult[index].count,
                rhrs: item.count,
              })
            );
            this.$nextTick(() => {
              drawRhtjLine("rhtjLine", seriesData, {
                thirtyPjdjl: this.baseData.averageClicks,
                thirtyPjrh: this.baseData.averageActivePopulation,
              });
              if (!this.rhtjChart) {
                this.rhtjChart = debounce(() => {
                  drawRhtjLine("rhtjLine", seriesData, {
                    thirtyPjdjl: this.baseData.averageClicks,
                    thirtyPjrh: this.baseData.averageActivePopulation,
                  }).resize();
                }, 100);
                window.addEventListener("resize", _this.rhtjChart, true);
              }
            });
          }
        })
        .catch(() => {});
    },
    // 空间预约次数 - 选中变化
    kjyycsSelOnChange() {
      this.getkjyycsData();
    },
    getkjyycsData() {
      let _this = this;
      this.$axios
        .get(getKjyycsUrl, {
          params: { selectLatitude: this.searchParam.kjyycs },
        })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            const seriesData = res.data.map((item) => ({
              name: item.date,
              count: item.count,
            }));
            this.$nextTick(() => {
              drawYycsLine("yycsLine", seriesData);

              if (!this.yycsChart) {
                this.yycsChart = debounce(() => {
                  drawYycsLine("yycsLine", seriesData).resize();
                }, 100);
                window.addEventListener("resize", _this.yycsChart, true);
              }
            });
          }
        })
        .catch(() => {});
    },

    handleAddPeopleChange() {},
    filterUnit(data) {
      if (!data) {
        return "";
      }
      return data.split("%")[0];
    },
  },
  destroyed() {
    let _this = this;
    window.removeEventListener("resize", _this.useNumberChart);
    window.removeEventListener("resize", _this.rfPieChart);
    window.removeEventListener("resize", _this.zcPieChart);
    window.removeEventListener("resize", _this.hylPieChart);
    window.removeEventListener("resize", _this.sjsbPieChart);
    window.removeEventListener("resize", _this.wclPieChart);
    window.removeEventListener("resize", _this.cmlPieChart);
    window.removeEventListener("resize", _this.sbqkChart);
    window.removeEventListener("resize", _this.djphChart);
    window.removeEventListener("resize", _this.pmqsChart);
    window.removeEventListener("resize", _this.hxlChart);
    window.removeEventListener("resize", _this.xflChart);
    window.removeEventListener("resize", _this.communityPmChart);
    window.removeEventListener("resize", _this.communityGenderChart);
    window.removeEventListener("resize", _this.addPeopleChart);
    window.removeEventListener("resize", _this.rhtjChart);
    window.removeEventListener("resize", _this.hyrsChart);
    window.removeEventListener("resize", _this.yycsChart);
  },
};
</script>
<style lang="less" scoped>
.mt20 {
  margin-top: 20px;
}

.opeDashboard {
  width: calc(100% + 40px);
  margin-left: -20px;
  box-sizing: border-box;
  height: calc(100% - 40px);
  padding: 20px 20px 20px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  // border: 1px solid red;

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  // &::-webkit-scrollbar {
  //   width: 6px;
  //   height: 6px;
  //   background-color: #f5f5f5;
  // }

  // /*定义滚动条轨道 内阴影+圆角*/
  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //   border-radius: 2px;
  //   background-color: #f5f5f5;
  // }

  // /*定义滑块 内阴影+圆角*/
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 2px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //   background-color: #f5f5f5;
  // }

  .title {
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 40px;
    }
  }

  .bg {
    background: #ffff;
    box-shadow: 0px 0px 8px 2px rgba(66, 114, 153, 0.08);
    border-radius: 15px;
  }

  // 基本信息
  &-msg {
    width: 100%;
    height: 230px;
    padding: 25px 43px 35px 29px;
    box-sizing: border-box;

    // 基本信息 上
    &-top {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      > div {
        box-sizing: border-box;
        width: calc((100% - 44px * 3) / 4);
        height: 122px;
        background: linear-gradient(180deg, #ddf2ff 0%, #ffffff 100%);
        border-radius: 8px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;

        img {
          width: 80px;
          height: 80px;
        }

        p {
          display: flex;
          flex-direction: column;

          span:nth-of-type(1) {
            font-weight: 600;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          span:nth-of-type(2) {
            font-weight: 400;
            font-size: 16px;
            color: #868686;
          }
        }
      }
    }

    // 基本信息 下
    &-bottom {
      margin-top: 20px;

      // 基本信息 下方第一个
      &-people {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 14px;

        > div {
          width: 100%;
          height: 130px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          border-radius: 12px;
          box-sizing: border-box;
          padding-left: 19px;

          > p {
            color: #ffffff;
            font-size: 14px;
          }

          > p:nth-of-type(1) {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 5px;
          }

          > p:last-child {
            font-weight: 600;
            font-size: 28px;
            margin-top: 10px;
          }
        }

        div:nth-of-type(1) {
          background: url("./img/houseBg.jpg") no-repeat;
          background-size: 100% 100%;
        }

        div:nth-of-type(2) {
          margin-top: 10px;
          background: url("./img/peopleBg.jpg") no-repeat;
          background-size: 100% 100%;
        }
      }

      // 基本信息 下方第二个
      &-community {
        width: 100%;
        height: 310px;
        box-sizing: border-box;
        display: flex;

        > div:nth-of-type(1) {
          width: 40%;
        }

        #communityPm {
          width: 100%;
          height: 250px;
        }

        #communityGender {
          width: 60%;
          height: 250px;
        }
      }

      // 基本信息 下方第三个
      &-pAndH {
        width: 100%;
        height: 310px;
        box-sizing: border-box;
        display: flex;

        &-text {
          width: 60%;

          &-content {
            width: 100%;

            > div {
              display: flex;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
              }

              p:nth-of-type(1) {
                font-weight: 400;
                font-size: 16px;
                color: #868686;
                white-space: nowrap;
              }

              p:nth-of-type(2) {
                font-size: 14px;
                color: #868686;
              }

              p:nth-of-type(3) {
                font-weight: 600;
                font-size: 28px;
                color: #2ca9f5;
              }
            }

            > div:nth-of-type(1) {
              margin: 10px 0 50px 0;
            }
          }
        }

        &-echarts {
          width: 40%;

          #rfPie {
            height: 150px;
          }

          #zcPie {
            height: 150px;
          }
        }
      }
    }
  }

  // 专项运营
  &-zxyyMsg {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    height: 558px;

    &-content {
      width: 100%;
      box-sizing: border-box;
      margin-top: 20px;

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        img {
          width: 45px;
          height: 45px;
          margin-right: 10px;
        }

        p:nth-of-type(1) {
          font-weight: 600;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 8px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p:nth-of-type(2) {
          font-size: 14px;
          font-weight: 400;
          color: #868686;
        }
      }
    }
  }

  &-common {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
  }

  //使用人数
  &-useNumber {
    height: 305px;

    #useNumberEchart {
      width: 100%;
      height: 250px;
    }
  }

  // 新增人数
  &-addPeople {
    height: 305px;

    #addPeopleEchart {
      width: 100%;
      height: 250px;
    }
  }

  // 活跃人数
  &-hyrs {
    height: 305px;

    &-echarts {
      display: flex;

      #hyrsPie {
        width: 30%;
        height: 250px;
      }

      #hyrsLine {
        width: 70%;
        height: 250px;
      }
    }
  }

  // 沉默人数
  &-cmrs {
    height: 305px;

    #silentPie {
      height: 250px;
    }
  }

  // 日活统计
  &-rhtj {
    height: 305px;

    #rhtjLine {
      height: 250px;
    }
  }

  // 点击排行
  &-djph {
    height: 305px;

    #djph {
      height: 250px;
    }
  }

  // 商品兑换分析
  &-spdh {
    height: 305px;

    &-content {
      margin-top: 20px;

      > p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        > span {
          display: inline-block;
          width: calc(100% / 3);
          text-align: center;
          flex-shrink: 0;

          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 100%;
            background: rgba(44, 169, 245, 0.2);
            text-align: center;
            font-size: 12px;
            color: #2ca9f5;
            margin-top: -2px;
          }
          &:nth-child(1) {
            width: 44%;
          }
          &:nth-child(2) {
            width: 32%;
          }
          &:nth-child(3) {
            width: 24%;
          }
        }

        img {
          margin-top: -2px;
          vertical-align: middle;
          margin-right: 5px;
        }
        &.pbody {
          height: 24px;
          line-height: 24px;
          > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
          > span:nth-child(3) {
            text-align: center;
          }
          > span:nth-child(2) {
            text-align: center;
            overflow: visible;
            i {
              text-align: left;
              font-style: normal;
              display: inline-block;
              width: 60px;
            }
          }
        }
      }

      > p:not(:first-child) {
        font-size: 14px;
        color: rgba(134, 134, 134, 0.85);
      }
    }
  }

  //积分订单核销率
  &-hxl {
    height: 305px;

    #hxlPie {
      height: 240px;
    }
  }

  //积分消费率
  &-xfl {
    height: 305px;

    #xflPie {
      height: 240px;
    }
  }

  //活动统计
  &-hdtj {
    height: 305px;

    &-content {
      width: 100%;
      margin-top: 5px;

      &-top {
        display: flex;
        justify-content: space-around;

        > div {
          box-sizing: border-box;
          width: calc((100% - 20px) / 2);
          height: 103px;
          padding-left: 20px;

          > p:nth-of-type(1) {
            font-size: 16px;
            color: #868686;
            margin-top: 14px;
          }

          > p:nth-of-type(2) {
            color: #868686;
          }

          > p:nth-of-type(3) {
            font-size: 28px;
            font-weight: 600;
            color: #2ca9f5;
          }
        }

        > div:nth-of-type(1) {
          background: url("./img/activeBg.png") no-repeat;
          background-size: 100% 100%;
        }

        > div:nth-of-type(2) {
          background: url("./img/hdPeopleBg.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      &-bottom {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        > div {
          box-sizing: border-box;
          width: calc((100% - 20px) / 2);
          height: 103px;
          background: url("./img/hdPeopleBg.png") no-repeat;
          background-size: 100% 100%;
          padding-left: 20px;

          > p:nth-of-type(1) {
            font-size: 16px;
            color: #868686;
            margin-top: 14px;
          }

          > p:nth-of-type(2) {
            color: #868686;
          }

          > p:nth-of-type(3) {
            font-size: 28px;
            font-weight: 600;
            color: #2ca9f5;
          }
        }
      }
    }
  }

  // 预约次数
  &-yycs {
    height: 268px;

    #yycsLine {
      height: 200px;
    }
  }

  // 排名前三
  &-pmqs {
    height: 268px;

    #pmqs {
      height: 200px;
    }
  }

  // 排名末三
  &-pmms {
    height: 268px;
    position: relative;

    &-content {
      width: 100%;

      > div {
        width: 110px;
        height: 110px;
        border-radius: 100%;
        position: absolute;

        > p:nth-of-type(1) {
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          margin-top: 23px;
        }

        > p:nth-of-type(2) {
          text-align: center;
          font-weight: 600;
          font-size: 18px;
          margin-top: 15px;
        }
      }

      > div:nth-of-type(1) {
        border: 1px solid #f88082;
        background: rgba(248, 128, 130, 0.1);
        top: 18%;
        left: 26%;

        p {
          color: #f88082;
        }
      }

      > div:nth-of-type(2) {
        border: 1px solid #2ca9f5;
        background: rgba(44, 169, 245, 0.1);
        top: 20%;
        left: 54%;

        p {
          color: #2ca9f5;
        }
      }

      > div:nth-of-type(3) {
        border: 1px solid #5f54fe;
        background: rgba(95, 84, 254, 0.1);
        bottom: 5%;
        left: 40%;

        p {
          color: #5f54fe;
        }
      }
    }
  }

  // 可预约空间数
  &-yykjs {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 268px;
    background: url("./img/kyykjBg.jpg") no-repeat;
    background-size: 100% 100%;
    padding-top: 30px;

    p:nth-of-type(1) {
      font-weight: 600;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
    }

    p:nth-of-type(2) {
      font-weight: 600;
      font-size: 36px;
      color: #ffffff;
      text-align: center;
      margin-top: 40px;
    }

    p:nth-of-type(3) {
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0px 0px 15px 15px;
      height: 41px;
      line-height: 41px;
      text-align: center;
      position: absolute;
      bottom: 0;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  // 事件上报
  &-sjsb {
    height: 237px;

    #sjsbPie {
      height: 190px;
    }
  }

  // 近三十日事件完成率
  &-wcl {
    height: 237px;

    #wclPie {
      height: 190px;
    }
  }

  // 今日设备情况
  &-sbqk {
    height: 237px;

    &-content {
      width: 100%;
      box-sizing: border-box;
      display: flex;

      #sbqkPie {
        width: 50%;
        height: 190px;
      }

      > div:nth-of-type(2) {
        box-sizing: border-box;
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #e1e1e1;

        img {
          width: 80px;
          height: 80px;
          margin-right: 25px;
        }

        p:nth-of-type(1) {
          font-size: 28px;
          font-weight: 600;
          color: #2ca9f5;
        }

        p:nth-of-type(2) {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #868686;
        }
      }
    }
  }
}

/deep/ .main-container-t {
  color: red;
}
</style>
